import { captureException, Severity } from '@sentry/react';
import LogRocket from 'logrocket';

/** Log errors to sentry and track in logrocket */
const errorLogger = (error: Error, level: Severity) => {
	// eslint-disable-next-line no-console
	console.error(error);
	// Log error to logrocket
	LogRocket.captureException(error);
	// If we have sentry config
	if (process.env.REACT_APP_SENTRY_DSN) {
		// Log error to sentry
		captureException(error, { level });
	}
};

export default errorLogger;
