import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IStoreVersionState } from './store-version.types';

// Create initial state for app slice
export const initialStoreVersionState: IStoreVersionState = {};

const storeVersionSlice = createSlice({
	name: 'storeVersion',
	initialState: initialStoreVersionState,
	reducers: {
		NEW_VERSION(state, action) {
			return {
				...state,
				newVersion: false,
				versionNumber: action.payload.versionNumber,
			};
		},
		SET_NEW_RELEASE(state) {
			return {
				...state,
				newVersion: true,
			};
		},
	},
});

// Destructure and export the plain action creators
export const { NEW_VERSION, SET_NEW_RELEASE } = storeVersionSlice.actions;

/** Thunk to update redux version number */
export const updateVersionNumber =
	(versionNumber: string) => async (dispatch: Dispatch) => {
		// Invalidate store using logout
		await dispatch(
			NEW_VERSION({
				versionNumber,
			}),
		);
	};

/** When a new app version is released */
export const setNewRelease = () => async (dispatch: Dispatch) => {
	// Update store to say there's a new release available
	await dispatch(SET_NEW_RELEASE());
};

export default storeVersionSlice.reducer;
