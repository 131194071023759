import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	ICreateSubscriptionPaymentIntent,
	ISubscription,
	ISubscriptionState,
} from './subscription.types';

import { IPaymentIntentResponse } from 'modules/core/payment/payment.types';

export const initialSubscriptionState: ISubscriptionState = {
	eventsInProgress: 0,
};

const subscriptionSlice = createSlice({
	name: 'subscription',
	initialState: initialSubscriptionState,
	reducers: {
		RESET_SUBSCRIPTION_STATE() {
			return { ...initialSubscriptionState };
		},
		GET_SUBSCRIPTION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_SUBSCRIPTION_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeSubscription: action.payload.data,
			};
		},
		GET_SUBSCRIPTION_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CANCEL_SUBSCRIPTION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			}
		},
		CANCEL_SUBSCRIPTION_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			}
		},
		CANCEL_SUBSCRIPTION_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			}
		},
		CREATE_SUBSCRIPTION_PAYMENT_INTENT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_SUBSCRIPTION_PAYMENT_INTENT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_SUBSCRIPTION_PAYMENT_INTENT_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_SUBSCRIPTION_PAYMENT_METHOD(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_SUBSCRIPTION_PAYMENT_METHOD_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

export const {
	RESET_SUBSCRIPTION_STATE,
	GET_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION,
	CREATE_SUBSCRIPTION_PAYMENT_INTENT,
	UPDATE_SUBSCRIPTION_PAYMENT_METHOD,
} = subscriptionSlice.actions;

/** Reset state */
export const resetSubscriptionState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_SUBSCRIPTION_STATE());
};

export const cancelSubscription = (subscriptionId: string) => async (dispatch: Dispatch) => {
	await dispatch(
		CANCEL_SUBSCRIPTION({
			request: {
				method: 'post',
				url: `1/subscriptions/${subscriptionId}/cancel`,
			},
		})
	)
}


export const createSubscriptionPaymentIntent =
	(data: ICreateSubscriptionPaymentIntent) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			CREATE_SUBSCRIPTION_PAYMENT_INTENT({
				request: {
					method: 'post',
					url: `1/payments/payment-intent/subscription`,
					data,
				},
			}),
		);

		return response.payload?.data as IPaymentIntentResponse;
	};

/** Get subscription plan */
export const getSubscription =
	(subscriptionId: string) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			GET_SUBSCRIPTION({
				request: {
					method: 'get',
					url: `1/subscriptions/${subscriptionId}`,
				},
			}),
		);

		return response.payload?.data as ISubscription;
	};

export const updateSubscriptionPaymentMethod =
	(subscriptionId: string, data: { stripePaymentMethodId: string }) =>
		async (dispatch: Dispatch) => {
			await dispatch(
				UPDATE_SUBSCRIPTION_PAYMENT_METHOD({
					request: {
						method: 'post',
						url: `1/subscriptions/${subscriptionId}/payment-method`,
						data,
					},
				}),
			);
		};

export default subscriptionSlice.reducer;
