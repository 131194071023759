import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { CustomerDataDetail, ICustomerState } from './customer.types'

export const initialCustomerState: ICustomerState = {
	eventsInProgress: 0,
};

const customerSlice = createSlice({
	name: 'customer',
	initialState: initialCustomerState,
	reducers: {
		RESET_CUSTOMER_STATE() {
			return { ...initialCustomerState };
		},
		SET_CUSTOMER_DETAILS(state, action) {
			return {
				...state,
				activeCustomerDetails: action.payload,
			};
		},
	},
});

export const {
	RESET_CUSTOMER_STATE,
	SET_CUSTOMER_DETAILS,
} = customerSlice.actions;

/** Reset state */
export const resetCustomerState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_CUSTOMER_STATE());
};

/** Set customer details */
export const updateCustomerDetails =
	(customerDetails: CustomerDataDetail[]) => async (dispatch: Dispatch) => {
		await dispatch(SET_CUSTOMER_DETAILS(customerDetails));
	};

export default customerSlice.reducer;
