import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import usePrefersColourScheme from './hooks/use-prefers-colour-scheme.hook'
import { ITheme } from './theme.types';

import darkTheme from 'assets/styles/variables/dark-theme';
import lightTheme from 'assets/styles/variables/light-theme';

/** Renders theme provider component */
const Theme: React.FC = ({ children }) => {
	const prefersColourScheme = usePrefersColourScheme()

	// Create theme object
	const theme = {
		light: {
			colors: {
				link: lightTheme.link,
				linkActive: lightTheme.linkActive,
				body: lightTheme.body,
				bgPrimary: lightTheme.bgPrimary,
				lblPrimary: lightTheme.white,
				lblSecondary: lightTheme.primary,
				radioChecked: lightTheme.text,
				checkboxChecked: lightTheme.primary,
				text: lightTheme.text,
				textButton: lightTheme.textButton,
				textLink: lightTheme.textLink,
				textLight: lightTheme.textLight,
				headings: lightTheme.headings,
				popupBackground: lightTheme.popupBackground,
				announcementBackground: lightTheme.announcementBackground,
				placeholder: lightTheme.placeholder,
				inputBorder: lightTheme.inputBorder,
				validationError: lightTheme.validationError,
			},
		},
		dark: {
			colors: {
				link: darkTheme.link,
				linkActive: darkTheme.linkActive,
				body: darkTheme.body,
				bgPrimary: darkTheme.bgPrimary,
				lblPrimary: darkTheme.white,
				lblSecondary: darkTheme.primary,
				radioChecked: darkTheme.text,
				checkboxChecked: darkTheme.primary,
				text: darkTheme.text,
				textButton: darkTheme.textButton,
				textLink: darkTheme.textLink,
				textLight: darkTheme.textLight,
				headings: darkTheme.headings,
				popupBackground: darkTheme.popupBackground,
				announcementBackground: darkTheme.announcementBackground,
				placeholder: darkTheme.placeholder,
				inputBorder: darkTheme.inputBorder,
				validationError: darkTheme.validationError
			},
		},
		'no-preference': {
			colors: {
				link: lightTheme.link,
				linkActive: lightTheme.linkActive,
				body: lightTheme.body,
				bgPrimary: lightTheme.bgPrimary,
				lblPrimary: lightTheme.white,
				lblSecondary: lightTheme.primary,
				radioChecked: lightTheme.text,
				checkboxChecked: lightTheme.primary,
				text: lightTheme.text,
				textButton: lightTheme.textButton,
				textLink: lightTheme.textLink,
				textLight: lightTheme.textLight,
				headings: lightTheme.headings,
				popupBackground: lightTheme.popupBackground,
				announcementBackground: lightTheme.announcementBackground,
				placeholder: lightTheme.placeholder,
				inputBorder: lightTheme.inputBorder,
				validationError: lightTheme.validationError,
			},
		}
	};

	// Local state for active theme
	const [activeTheme, setActiveTheme] = useState<ITheme>(theme[prefersColourScheme]);

	useEffect(() => {
		setActiveTheme(theme[prefersColourScheme]);
	}, [prefersColourScheme]);

	return <ThemeProvider theme={activeTheme}>{children}</ThemeProvider>;
};

export default Theme;
