import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	ICreateSubscriptionPaymentIntent,
	ICreateSubscriptionRequestDto,
	ISubscription,
	ISubscriptionPlanState,
} from './subscription-plan.types';

import { IPaymentIntentResponse } from 'modules/core/payment/payment.types';


export const initialSubscriptionPlanState: ISubscriptionPlanState = {
	eventsInProgress: 0,
	acceptedPrivacyTerms: false,
	acceptedPaymentAuthorisationTerms: false,
};

const subscriptionPlanSlice = createSlice({
	name: 'subscriptionPlan',
	initialState: initialSubscriptionPlanState,
	reducers: {
		RESET_SUBSCRIPTION_PLAN_STATE() {
			return { ...initialSubscriptionPlanState };
		},
		GET_SUBSCRIPTION_PLAN(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_SUBSCRIPTION_PLAN_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeSubscriptionPlan: action.payload.data,
			};
		},
		GET_SUBSCRIPTION_PLAN_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_SUBSCRIPTION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_SUBSCRIPTION_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeSubscription: action.payload.data,
			};
		},
		CREATE_SUBSCRIPTION_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_SUBSCRIPTION_PAYMENT_INTENT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_SUBSCRIPTION_PAYMENT_INTENT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_SUBSCRIPTION_PAYMENT_INTENT_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_ACCEPTED_PRIVACY_TERMS(state, action) {
			return {
				...state,
				acceptedPrivacyTerms: action.payload,
			};
		},
		SET_ACCEPTED_PAYMENT_AUTHORISATION_TERMS(state, action) {
			return {
				...state,
				acceptedPaymentAuthorisationTerms: action.payload,
			};
		},
		RESET_ACCEPTED_TERMS(state) {
			return {
				...state,
				acceptedPrivacyTerms: false,
				acceptedPaymentAuthorisationTerms: false,
			}
		}
	},
});

export const {
	RESET_SUBSCRIPTION_PLAN_STATE,
	GET_SUBSCRIPTION_PLAN,
	CREATE_SUBSCRIPTION,
	CREATE_SUBSCRIPTION_PAYMENT_INTENT,
	SET_ACCEPTED_PRIVACY_TERMS,
	SET_ACCEPTED_PAYMENT_AUTHORISATION_TERMS,
	RESET_ACCEPTED_TERMS,
} = subscriptionPlanSlice.actions;

/** Reset state */
export const resetSubscriptionPlanState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_SUBSCRIPTION_PLAN_STATE());
};

/** Create subscription */
export const createSubscription =
	(data: ICreateSubscriptionRequestDto) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			CREATE_SUBSCRIPTION({
				request: {
					method: 'post',
					url: `1/subscriptions`,
					data,
				},
			}),
		);

		return response.payload?.data as ISubscription;
	};

export const createSubscriptionPaymentIntent =
	(data: ICreateSubscriptionPaymentIntent) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			CREATE_SUBSCRIPTION_PAYMENT_INTENT({
				request: {
					method: 'post',
					url: `1/payments/payment-intent/subscription`,
					data,
				},
			}),
		);

		return response.payload?.data as IPaymentIntentResponse;
	};

/** Set accepted privacy terms */
export const setAcceptedPrivacyTerms =
	(accepted: boolean) => async (dispatch: Dispatch) => {
		await dispatch(SET_ACCEPTED_PRIVACY_TERMS(accepted));
	};

/** Set accepted payment authorisation terms */
export const setAcceptedPaymentAuthorisationTerms =
	(accepted: boolean) => async (dispatch: Dispatch) => {
		await dispatch(SET_ACCEPTED_PAYMENT_AUTHORISATION_TERMS(accepted));
	};

/** Get subscription plan */
export const getSubscriptionPlan =
	(subscriptionPlanId: string) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			GET_SUBSCRIPTION_PLAN({
				request: {
					method: 'get',
					url: `1/subscription-plans/${subscriptionPlanId}`,
				},
			}),
		);

		return response.payload?.data;
	};

export const resetAcceptedTerms = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_ACCEPTED_TERMS())
}

export default subscriptionPlanSlice.reducer;
