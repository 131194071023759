import { AxiosError } from 'axios';

import { fireDialog } from '../../dialog/dialog.service';
import { intl } from '../../i18n/i18n.config';

// Function to create errors on specific auth endpoints
export const authErrors = (error: AxiosError) => {
	if (
		error?.response?.config.url === 'auth/login' &&
		error.response.status === 401
	) {
		// Show login failed alert
		fireDialog({
			title: intl.formatMessage({
				id: 'errors.forms.login.failed.title',
			}),
			text: intl.formatMessage({
				id: 'errors.forms.login.failed.text',
			}),
		});
	}
};
