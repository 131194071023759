import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	ICreatePaymentRequest,
	IPaymentDetails,
	IPaymentState,
	ITip,
} from './payment-request.types';

import { IPaymentIntentResponse, ISetupIntentResponse } from 'modules/core/payment/payment.types';


// Create initial state
export const initialPaymentRequestState: IPaymentState = {
	eventsInProgress: 0,
	cardPaymentVisibility: false,
	isPaymentComplete: false,
	showCustomTipInput: false,
	showDonationInput: false,
};

const paymentSlice = createSlice({
	name: 'paymentRequest',
	initialState: initialPaymentRequestState,
	reducers: {
		RESET_PAYMENT_STATE() {
			return { ...initialPaymentRequestState };
		},
		SET_PAYMENT_COMPLETE(state, action) {
			return {
				...state,
				isPaymentComplete: action.payload,
			};
		},
		CREATE_PAYMENT_REQUEST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_PAYMENT_REQUEST_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_PAYMENT_REQUEST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_SETUP_INTENT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_SETUP_INTENT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_SETUP_INTENT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PAYPOINT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PAYPOINT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activePaypoint: action.payload.data,
			};
		},
		GET_PAYPOINT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PAYMENT_REQUEST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PAYMENT_REQUEST_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activePaymentRequest: action.payload.data,
			};
		},
		GET_PAYMENT_REQUEST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_CARD_PAYMENT_VISIBILITY(state, action) {
			return {
				...state,
				cardPaymentVisibility: action.payload,
			};
		},
		SET_SUCCESSFUL_PAYMENT(state, action) {
			return {
				...state,
				successfulPayment: action.payload,
			};
		},
		SET_TIP(state, action) {
			return {
				...state,
				tip: action.payload,
			};
		},
		SET_SHOW_CUSTOM_TIP_INPUT(state, action) {
			return {
				...state,
				showCustomTipInput: action.payload,
			}
		},
		SET_DONATION(state, action) {
			return {
				...state,
				donationUnits: action.payload,
			}
		},
		SET_SHOW_DONATION_INPUT(state, action) {
			return {
				...state,
				showDonationInput: action.payload,
			}
		},
		CLEAR_ACTIVE_PAYMENTS(state) {
			return {
				...state,
				activePaypoint: undefined,
				activePaymentRequest: undefined,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_PAYMENT_STATE,
	SET_PAYMENT_COMPLETE,
	CREATE_PAYMENT_REQUEST,
	CREATE_PAYMENT_REQUEST_FAIL,
	CREATE_PAYMENT_REQUEST_SUCCESS,
	CREATE_SETUP_INTENT,
	CREATE_SETUP_INTENT_FAIL,
	CREATE_SETUP_INTENT_SUCCESS,
	GET_PAYPOINT,
	GET_PAYPOINT_FAIL,
	GET_PAYPOINT_SUCCESS,
	GET_PAYMENT_REQUEST,
	GET_PAYMENT_REQUEST_FAIL,
	GET_PAYMENT_REQUEST_SUCCESS,
	SET_CARD_PAYMENT_VISIBILITY,
	SET_SUCCESSFUL_PAYMENT,
	SET_TIP,
	SET_SHOW_CUSTOM_TIP_INPUT,
	SET_DONATION,
	SET_SHOW_DONATION_INPUT,
	CLEAR_ACTIVE_PAYMENTS,
} = paymentSlice.actions;

/** Reset state */
export const resetPaymentState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_PAYMENT_STATE());
};

/** Create a stripe payment request */
export const getPaypoint =
	(payPointId: string) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			GET_PAYPOINT({
				request: {
					method: 'get',
					url: `1/public/pay-points/${payPointId}`,
				},
			}),
		);

		return response.payload?.data;
	};

/** Set payment complete status */
export const setPaymentComplete =
	(visible: boolean) => async (dispatch: Dispatch) => {
		await dispatch(SET_PAYMENT_COMPLETE(visible));
	};

/** Create a stripe payment request */
export const createPaymentRequest =
	(paymentRequest: ICreatePaymentRequest) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			CREATE_PAYMENT_REQUEST({
				request: {
					method: 'post',
					url: `1/payments/payment-intent`,
					data: paymentRequest,
				},
			}),
		);

		return response.payload?.data as IPaymentIntentResponse;
	};

/** Create a stripe setup intent */
export const createSetupIntent = () => async (dispatch: Dispatch) => {
	const response = await dispatch(
		CREATE_SETUP_INTENT({
			request: {
				method: 'get',
				url: '1/payments/setup-intent',
			},
		}),
	);

	return response.payload?.data as ISetupIntentResponse;
};

/** Get a lopay payment request */
export const getPaymentRequest =
	(paymentRequestId: string) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			GET_PAYMENT_REQUEST({
				request: {
					method: 'get',
					url: `1/public/payment-requests/${paymentRequestId}`,
				},
			}),
		);

		return response.payload?.data;
	};

/** Save successful payment details in state */
export const setSuccessfulPayment =
	(paymentDetails: IPaymentDetails) => async (dispatch: Dispatch) => {
		await dispatch(SET_SUCCESSFUL_PAYMENT(paymentDetails));
	};

/** Clear active payments from state */
export const clearActivePayments = () => async (dispatch: Dispatch) => {
	await dispatch(CLEAR_ACTIVE_PAYMENTS());
};

/** Show/hide card payment */
export const setCardPaymentVisibility =
	(visible: boolean) => async (dispatch: Dispatch) => {
		await dispatch(SET_CARD_PAYMENT_VISIBILITY(visible));
	};

/** Set tip in state */
export const setTip = (tip: ITip | undefined) => async (dispatch: Dispatch) => {
	await dispatch(SET_TIP(tip))
}

/** Set show custom tip input */
export const setShowCustomTipInput = (show: boolean) => async (dispatch: Dispatch) => {
	await dispatch(SET_SHOW_CUSTOM_TIP_INPUT(show))
}

/** Set donation in state */
export const setDonation = (donationUnits: number | undefined) => async (dispatch: Dispatch) => {
	await dispatch(SET_DONATION(donationUnits))
}

/** Set show donation input */
export const setShowDonationInput = (show: boolean) => async (dispatch: Dispatch) => {
	await dispatch(SET_SHOW_DONATION_INPUT(show))
}

export default paymentSlice.reducer;
