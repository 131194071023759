import { configureStore } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import axiosMiddleware from 'redux-axios-middleware';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import httpClient, { httpMiddlewareConfig } from '../http/http.config';
import rootReducer from './root.reducer';

// Configure redux-persist
const persistConfig = {
	key: 'wow-root',
	storage,
};

// Persist root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure redux store using redux toolkit
const store = configureStore({
	reducer: persistedReducer,
	middleware: [
		thunk,
		axiosMiddleware(httpClient, httpMiddlewareConfig),
		LogRocket.reduxMiddleware(),
	],
});

// Persist store
export const persistor = persistStore(store);

// @ts-ignore // If we're in development mode
if (process.env.NODE_ENV === 'development' && module.hot) {
	// @ts-ignore // Hot reload the root reducer
	module.hot.accept('./root.reducer', () => {
		// eslint-disable-next-line global-require
		const newRootReducer = require('./root.reducer').default;
		store.replaceReducer(newRootReducer);
	});
}

// Create a type for dispatches
export type AppDispatch = typeof store.dispatch;

export default store;
