import { createBrowserHistory } from 'history';
import React, { lazy } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { GuardProvider } from 'react-router-guards';
import {
	GuardToRoute,
	Next,
	GuardFunctionRouteProps,
	GuardFunction,
} from 'react-router-guards/dist/types';

const NotFoundPage = lazy(() => import('./not-found.page'));
const PaymentPage = lazy(() => import('modules/payment-request/payment-request.page'));
const SubscriptionPlanPage = lazy(
	() => import('modules/subscription-plan/subscription-plan.page'),
);
const SubscriptionPage = lazy(
	() => import('modules/subscription/subscription.page'),
);
const OpenBankingRedirectPage = lazy(
	() => import('modules/open-banking-redirect/open-banking-redirect.page')
)

// Create and export browser history
export const history = createBrowserHistory();

/** Router component containing all application routes */
const AppRouter: React.FC = () => {
	// TODO - Get auth from state
	const hasAuth = false;

	/** Route guard for routes requiring login */
	const requireLogin: GuardFunction = async (
		to: GuardToRoute,
		_: GuardFunctionRouteProps | null,
		next: Next,
	) => {
		// If route requires auth
		if (to.meta.auth) {
			hasAuth ? next() : next.redirect('/');
		} else {
			next();
		}
	};

	return (
		<BrowserRouter>
			<GuardProvider guards={[requireLogin]} error={NotFoundPage}>
				<Switch>
					<Route exact path="/" component={PaymentPage} />
					<Route
						exact
						path="/paypoint/:payPointId"
						component={PaymentPage}
					/>
					<Route
						exact
						path="/request/:paymentRequestId"
						component={PaymentPage}
					/>
					<Route
						exact
						path="/subscription-plan/:subscriptionPlanId"
						component={SubscriptionPlanPage}
					/>
					<Route
						exact
						path="/subscription/:subscriptionId"
						component={SubscriptionPage}
					/>
					<Route
						exact
						path="/open-banking/redirect"
						component={OpenBankingRedirectPage}
					/>
					<Route component={NotFoundPage} />
				</Switch>
			</GuardProvider>
		</BrowserRouter>
	);
};

export default AppRouter;
