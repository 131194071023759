const colours = {
	bastille: '#1C1520',
	porcelain: '#EFF0F1',
	balticSea: '#26222A',
	mineShaft: '#333333',
	azureRadiance: '#007AFF',
	cornflowerBlue: '#6195ED',
	dodgerBlue: '#407BFF'
}

export default colours;
