import { createGlobalStyle } from 'styled-components';

import fontFaces from './global/font-faces';
import globalFonts from './global/fonts';
import globalStructure from './global/structure';
import sweetAlert from './global/sweetAlert';

import { ITheme } from 'modules/core/theme/theme.types';

/** Global styles */
export const GlobalStyle = createGlobalStyle`
	${fontFaces}
	${({ theme }: { theme: ITheme }) => globalFonts(theme)}
	${({ theme }: { theme: ITheme }) => globalStructure(theme)}
	${({ theme }: { theme: ITheme }) => sweetAlert(theme)}
`;
