import { lighten, rgba } from 'polished';

import colours from './colours'

/** Dark theme colour variables */
const darkTheme = {
	black: '#000000',
	white: '#ffffff',
	body: colours.bastille,
	neutral: '#f2f2f2',
	neutralLight: '#fefefe',
	borders: rgba(0, 0, 0, 0.2),
	inputBorder: '#352F3A',
	text: '#FFFFFF',
	textButton: colours.dodgerBlue,
	textLink: colours.cornflowerBlue,
	textLight: rgba('#FFFFFF', 0.8),
	placeholder: rgba('#FFFFFF', 0.6),
	bgPrimary: colours.balticSea,
	selectBackground: '#e9edf3',
	popupBackground: rgba(217, 217, 217, 0.1),
	announcementBackground: colours.azureRadiance,
	headings: '',
	primary: '#717CBC',
	secondary: '#5AF091',
	genericError: '#D63636',
	get link() {
		return this.primary;
	},
	get linkHover() {
		return lighten(0.02, this.primary);
	},
	get linkActive() {
		return lighten(0.08, this.primary);
	},
	get validationDisabled() {
		return this.neutral;
	},
	validationError: '#dc3545',
	validationSuccess: '#28a745',
	validationWarning: '#ffc107',
};

export default darkTheme;
