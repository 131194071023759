import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import styled from 'styled-components';
import { ModalProvider } from 'styled-react-modal';


import { GlobalStyle } from './assets/styles/app';
import ErrorBoundary from './modules/core/error/error-boundary.component';
import store, { persistor } from './modules/core/state/store.config';
import ThemeProvider from './modules/core/theme/theme-provider.component'

import { modalBackground } from 'components/modal/modal.component';

const AppRouter = lazy(
	() => import('./modules/core/routing/app-router.component'),
);
const Dialog = lazy(() => import('modules/core/dialog/dialog.component'));
const I18nProvider = lazy(
	() => import('./modules/core/i18n/i18n-provider.component'),
);
const Loading = lazy(() => import('components/loading/loading.component'));
const StoreVersion = lazy(
	() => import('modules/core/state/store-version/store-version.component'),
);
interface IStyledAppWrapperProps {
	viewHeight: number;
}

const StyledAppWrapper = styled.div<IStyledAppWrapperProps>`
	width: 100%;
	height: calc(${({ viewHeight }) => viewHeight}px * 100);
	position: relative;
`;

/** Renders app component */
const App: React.FC = () => {
	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01,
	);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	useEffect(() => {
		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => window.removeEventListener('resize', getSetViewHeight);
	}, []);

	return (
		<ErrorBoundary>
			<Suspense fallback={null}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<StoreVersion />
						<I18nProvider>
							<ThemeProvider>
								<GlobalStyle />
								<ModalProvider backgroundComponent={modalBackground}>
									<Loading />
									<StyledAppWrapper viewHeight={viewHeight}>
										<AppRouter />
									</StyledAppWrapper>
								</ModalProvider>
								<Dialog />
							</ThemeProvider>
						</I18nProvider>
					</PersistGate>
				</Provider>
			</Suspense>
		</ErrorBoundary>
	);
};

export default App;
