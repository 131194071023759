import { configureScope, init } from '@sentry/react';
import LogRocket from 'logrocket';

/** Initialises logging tools */
const initialiseLogs = () => {
	// If we're not in local dev environment
	if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
		// eslint-disable-next-line no-console
		console.log(
			`%c  LoPay Web Consumer (v${process.env.REACT_APP_VERSION}) - ${process.env.REACT_APP_ENVIRONMENT}`,
			[
				'background-color: #101010;',
				'background-image: url("https://cms.orderpay.com/apple-touch-icon.png")',
				'background-size: contain',
				'background-position: top left',
				'background-repeat: no-repeat',
				'padding: 20px 20px 20px 50px',
				'color: white',
				'font-size: 20px;',
			].join(';'),
		);
		try {
			// Initialise logrocket
			LogRocket.init('taaam2/orderpay-web-ordering', {
				release: process.env.REACT_APP_VERSION,
				// Sanitise some network requests
				network: {
					requestSanitizer: (request) =>
						// Return the modified request
						request,
					responseSanitizer: (response) => {
						if (response && response.body) {
							try {
								JSON.parse(response.body);
							} catch (e) {
								return response;
							}

							// Parse body string
							const body = JSON.parse(response.body);

							// return response
							return {
								...response,
								body: JSON.stringify(body),
							};
						}
						return response;
					},
				},
			});

			// If we have sentry config
			if (process.env.REACT_APP_SENTRY_DSN) {
				// Initialise sentry
				init({
					dsn: process.env.REACT_APP_SENTRY_DSN,
					release: process.env.REACT_APP_VERSION,
				});

				// Get logrocket session URL
				LogRocket.getSessionURL((sessionURL) => {
					// Set sentry scope
					configureScope((scope) => {
						// Add logrocket session URL to sentry extra data
						scope.setExtra('sessionURL', sessionURL);
					});
				});
			}

			// eslint-disable-next-line no-console
			console.info(
				`Logging initialised: (v${process.env.REACT_APP_VERSION}) - ${process.env.REACT_APP_ENVIRONMENT}`,
			);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('Logging: Failed to load logging plugins.');
		}
	}
};

export default initialiseLogs;
