import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IThemeState } from './theme.types';

// Create initial state
export const initialThemeState: IThemeState = {
	mode: 'brand',
};

const themeSlice = createSlice({
	name: 'theme',
	initialState: initialThemeState,
	reducers: {
		SET_THEME_MODE(state, action) {
			return {
				...state,
				mode: action.payload,
			};
		},
		RESET_THEME_STATE() {
			return { ...initialThemeState };
		},
	},
});

// Destructure and export the plain action creators
export const { RESET_THEME_STATE, SET_THEME_MODE } = themeSlice.actions;

/** Reset state */
export const resetThemeState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_THEME_STATE());
};

/** Set theme mode */
export const setThemeMode =
	(mode: string) =>
		async (dispatch: Dispatch, getState: () => { theme: IThemeState }) => {
			const currentThemeMode = getState().theme.mode;
			currentThemeMode !== mode && (await dispatch(SET_THEME_MODE(mode)));
		};

/** Set theme mode */
export const resetThemeMode = () => async (dispatch: Dispatch) => {
	await dispatch(SET_THEME_MODE(initialThemeState.mode));
};

export default themeSlice.reducer;
