/** Font faces */
const fontFaces = `
	@font-face {
		font-family: 'Poppins';
		src: local('Poppins'), local('Poppins'),
			url(${'/fonts/Poppins-Regular.woff2'}) format('woff2'),
			url(${'/fonts/Poppins-Regular.woff'}) format('woff');
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: 'Poppins';
		src: local('Poppins'), local('Poppins'),
			url(${'/fonts/Poppins-Light.woff2'}) format('woff2'),
			url(${'/fonts/Poppins-Light.woff'}) format('woff');
		font-weight: 300;
		font-style: normal;
	}
	@font-face {
		font-family: 'Poppins';
		src: local('Poppins'), local('Poppins'),
			url(${'/fonts/Poppins-LightItalic.woff2'}) format('woff2'),
			url(${'/fonts/Poppins-LightItalic.woff'}) format('woff');
		font-weight: 300;
		font-style: italic;
	}
	@font-face {
		font-family: 'Poppins';
		src: local('Poppins'), local('Poppins'),
			url(${'/fonts/Poppins-Medium.woff2'}) format('woff2'),
			url(${'/fonts/Poppins-Medium.woff'}) format('woff');
		font-weight: 500;
		font-style: normal;
	}
	@font-face {
		font-family: 'Poppins';
		src: local('Poppins'), local('Poppins'),
			url(${'/fonts/Poppins-SemiBold.woff2'}) format('woff2'),
			url(${'/fonts/Poppins-SemiBold.woff'}) format('woff');
		font-weight: 600;
		font-style: normal;
	}
	@font-face {
		font-family: 'Poppins';
		src: local('Poppins'), local('Poppins'),
			url(${'/fonts/Poppins-Bold.woff2'}) format('woff2'),
			url(${'/fonts/Poppins-Bold.woff'}) format('woff');
		font-weight: 700;
		font-style: normal;
	}
`;

export default fontFaces;
