import Swal, { SweetAlertOptions } from 'sweetalert2';

import dialogConfig from './dialog.config';

/** Fire an alert */
export const fireDialog = (options: SweetAlertOptions) =>
	// Fire an alert
	Swal.fire({
		...dialogConfig,
		...options,
	});

// Export dialog service as object
export const dialogService = { fireDialog };
