import { captureEvent, Severity } from '@sentry/react';
import LogRocket from 'logrocket';

/** Log events to sentry and track in logrocket */
const eventLogger = (error: {
	message: string;
	level?: Severity;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	extra: any;
}) => {
	// eslint-disable-next-line no-console
	console.error(error);
	// If we have sentry config
	if (process.env.REACT_APP_SENTRY_DSN) {
		// Log error in sentry
		captureEvent(error);
	}
	// Track error in logrocket
	LogRocket.track(error.message);
};

export default eventLogger;
