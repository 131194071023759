import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { II18nState, ITranslation } from './i18n.types';

import { flattenObject } from 'helpers/generic-helpers.helper';
import { IFlattenObjectInput } from 'helpers/helpers.types';

// Create initial state
export const initialI18nState: II18nState = {
	translations: [],
	loading: false,
};

const i18nSlice = createSlice({
	name: 'i18n',
	initialState: initialI18nState,
	reducers: {
		GET_TRANSLATIONS(state, action) {
			return {
				...state,
				loading: true,
			};
		},
		GET_TRANSLATIONS_SUCCESS(state, action) {
			const { data } = action.payload;

			const translations: ITranslation[] = Object.entries(data)
				// @ts-ignore
				.map(
					([locale, messages]): ITranslation => ({
						locale: locale.replace('_', '-'),
						messages: flattenObject(messages as IFlattenObjectInput),
					}),
				);

			return {
				...state,
				translations,
				loading: false,
			};
		},
		GET_TRANSLATIONS_FAIL(state, action) {
			return {
				...state,
				loading: false,
			};
		},
	},
});

export const getTranslations = () => async (dispatch: Dispatch) => {
	// Create request
	await dispatch(
		GET_TRANSLATIONS({
			request: {
				method: 'get',
				url: '/1/public/locales',
			},
		}),
	);
};

export const { GET_TRANSLATIONS } = i18nSlice.actions;

export default i18nSlice.reducer;
