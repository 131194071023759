import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { ILoadingState } from './loading.types';

export const initialLoadingState: ILoadingState = {
	eventsInProgress: 0,
	loadingConfig: {
		loadingMessage: '',
		loadingTimeout: 0,
	},
};

const loadingSlice = createSlice({
	name: 'loading',
	initialState: initialLoadingState,
	reducers: {
		ADD_GENERAL_LOADING_EVENT(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		REMOVE_GENERAL_LOADING_EVENT(state) {
			return {
				...state,
				eventsInProgress:
					state.eventsInProgress > 0 ? state.eventsInProgress - 1 : 0,
			};
		},
		SET_LOADING_CONFIG(state, action) {
			return {
				...state,
				loadingConfig: action.payload?.data,
			};
		},
		RESET_LOADING_STATES() {
			return initialLoadingState;
		},
	},
});

// Destructure and export the plain action creators
export const {
	ADD_GENERAL_LOADING_EVENT,
	REMOVE_GENERAL_LOADING_EVENT,
	SET_LOADING_CONFIG,
	RESET_LOADING_STATES,
} = loadingSlice.actions;

/** Thunk to dispatch add loading event to loading state */
export const addLoadingEvent = () => (dispatch: Dispatch) =>
	dispatch(ADD_GENERAL_LOADING_EVENT());

/** Thunk to remove loading event to loading state */
export const removeLoadingEvent = () => (dispatch: Dispatch) =>
	dispatch(REMOVE_GENERAL_LOADING_EVENT());

/** Thunk to set loading config in loading state */
export const setLoadingConfig =
	(config: { loadingMessage?: string; loadingTimeout?: number }) =>
		(dispatch: Dispatch) =>
			dispatch(SET_LOADING_CONFIG({ data: config }));

/** Thunk to dispatch reset loading state */
export const resetLoadingStates = () => (dispatch: Dispatch) =>
	dispatch(RESET_LOADING_STATES());

export default loadingSlice.reducer;
