import { AxiosError } from 'axios';

import { fireDialog } from '../dialog/dialog.service';
import { intl } from '../i18n/i18n.config';
import { authErrors } from './helpers/endpoint-error.helper';

type ErrorCategory = 'auth' | 'payment-request'

interface IExcludedEndpoint {
	route: string | RegExp;
	category: ErrorCategory;
	errors: number[];
}

/** Handle an error response from the API */
export const handleApiError = async (error?: AxiosError) => {
	// Get error code from error
	const errorCode: string | undefined = error?.code;
	// Get statuscode from error
	const statusCode: number | undefined = error?.response?.status;
	// Get requested endpoint from error
	const endpoint: string | undefined = error?.response?.config?.url;
	// Endpoint is excluded from generic response
	let isExcluded: boolean = false;

	// Endpoints with specific error handling
	const excludedEndpoints: IExcludedEndpoint[] = [
		{
			route: 'auth/login',
			category: 'auth',
			errors: [401],
		},
		{
			route: 'auth/social/google',
			category: 'auth',
			errors: [404],
		},
		{
			route: 'auth/social/apple',
			category: 'auth',
			errors: [404],
		},
		{
			route: 'auth/social/facebook',
			category: 'auth',
			errors: [404],
		},
		{
			route: /public\/payment-requests\/.*/g,
			category: 'payment-request',
			errors: [400],
		},
	];

	// Check if requested endpoint is in excluded list
	const endpointExcluded = excludedEndpoints.find((excludedEndpoint) => {
		if (!endpoint) {
			return false;
		}

		// If string then just use ===
		if (typeof excludedEndpoint.route === 'string') {
			return excludedEndpoint.route === endpoint;
		}

		// else assume its regex
		return excludedEndpoint.route.test(endpoint);
	});

	// If endpoint is excluded + status code is set
	if (endpointExcluded && statusCode) {
		// If endpoint has this status code excluded
		isExcluded = endpointExcluded?.errors.includes(statusCode);
	}

	// Define responses we'll handle in a generic way
	const genericResponses = [400, 401, 403, 404, 409, 422, 424, 500];

	// If endpoint isn't excluded
	if (!isExcluded) {
		// If we have a status code and it's one of the generic responses we handle
		if (statusCode && genericResponses.includes(statusCode)) {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: `errors.responses.${statusCode}.title`,
				}),
				text: error?.response?.data?.message
					? error?.response?.data?.message
					: intl.formatMessage({
						id: `errors.responses.${statusCode}.text`,
					  }),
				icon: 'error',
			});
		} else if (
			// Timeout
			errorCode === 'ECONNABORTED'
		) {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.responses.timeout.title',
				}),
				text: intl.formatMessage({
					id: 'errors.responses.timeout.text',
				}),
				icon: 'error',
			});
		} else if (
			// Network error
			!!error?.isAxiosError &&
			!error?.response
		) {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.responses.network.title',
				}),
				text: intl.formatMessage({
					id: 'errors.responses.network.text',
				}),
				icon: 'error',
			});
		} else {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.responses.general.title',
				}),
				text: error?.response?.data?.message
					? error?.response?.data?.message
					: intl.formatMessage({
						id: 'errors.responses.general.text',
					  }),
				icon: 'error',
			});
		}
	} else if (endpointExcluded?.category === 'payment-request') {
		if (error?.response?.data?.title === 'Cancelled payment link') {
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.paymentRequestCancelled.title',
				}),
				text: intl.formatMessage({
					id: 'errors.paymentRequestCancelled.text',
				}),
				icon: 'info',
			})
		} else {
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.alreadyUsedPaymentRequest.title',
				}),
				text: intl.formatMessage({
					id: 'errors.alreadyUsedPaymentRequest.text',
				}),
				icon: 'info',
			})
		}
	} else {
		// run excluded endpoint
		authErrors(error!);
	}

	// Pass error back to caller
	return Promise.reject(error);
};

// Export error service as object
export const errorService = { handleApiError };
