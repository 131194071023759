import fonts from '../variables/fonts';

import { ITheme } from 'modules/core/theme/theme.types';

/** Global structure styles */
function globalStructure(theme: ITheme) {
	return `
		html {
			font-family: ${fonts.family};
			font-size: ${fonts.sizes.base};

			body {
				margin: 0;
				line-height: ${fonts.lineHeight.base};
				font-weight: ${fonts.weights.default};
				font-size: ${fonts.sizes.standard};
				font-family: ${fonts.family};
				color: ${theme.colors.text};
				background: ${theme.colors.body};
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				input, select, textarea, button {
					font-family: ${fonts.family};
				}
			}

			* {
				box-sizing: border-box;
			}
		}
	`;
}

export default globalStructure;
